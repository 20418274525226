import React from 'react'
import { Helmet } from 'react-helmet-async'

import useSiteSettings from '../../hooks/useSiteSettings'
import { isUserBlockedError } from '../../lib/auth'
import { isStorageDisabledError } from '../../utils/storage'
import ContactSupportMessage from '../ContactSupportMessage'
import FullPageError from '../FullPageError'

export interface LoginErrorProps {
  error: Error
}

export const LoginError: React.FC<LoginErrorProps> = ({ error }) => {
  const { blockedUserErrorMessage } = useSiteSettings() || {}
  const isUserBlocked = isUserBlockedError(error)
  return (
    <>
      <Helmet title="Login Error" />
      {isUserBlocked ? (
        <FullPageError data-cy="blocked-user-login-error">
          {blockedUserErrorMessage ??
            'Contact customer service for support as your account is currently on hold'}
        </FullPageError>
      ) : (
        <FullPageError>
          {isStorageDisabledError(error)
            ? 'Cookies and browser access to local storage is required to login. Please update your browser to the latest version, and update these settings to continue.'
            : 'A login error has occurred, and we have been notified.'}
          <small>
            <ContactSupportMessage />
          </small>
        </FullPageError>
      )}
    </>
  )
}
