import { useContext, useMemo } from 'react'

import { Maybe, SanityRoleBasedAccessControl } from '../../../graphql/gatsby'
import { SanityUserRolesContext } from '../context'
import { isSanityUserRole } from '../roles'

export interface UseSanityRoleBasedAccessControl {
  hasAccess: boolean
  isLoading: boolean
}

export function useSanityRoleBasedAccessControl({
  roleBasedAccessControl,
}: {
  roleBasedAccessControl: Maybe<SanityRoleBasedAccessControl> | undefined
}): UseSanityRoleBasedAccessControl {
  const { isLoading, userRoles } = useContext(SanityUserRolesContext)

  const hasAccess = useMemo(() => {
    if (!roleBasedAccessControl || roleBasedAccessControl.enabled !== true) {
      return true
    }

    const roles = (roleBasedAccessControl.roles || []).filter(isSanityUserRole)

    return roles.some((role) => userRoles.includes(role))
  }, [roleBasedAccessControl, userRoles])

  return { hasAccess, isLoading }
}
