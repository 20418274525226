import BlockContentImport from '@sanity/block-content-to-react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import styled from 'styled-components'

import DefaultPageHero from '../../sanity-page/PageHero'

export const PageHero = styled(DefaultPageHero)`
  @media (min-width: 992px) {
    && {
      .hero-bg {
        top: -2.5rem;
      }
      .content {
        .tag-and-cta {
          margin-top: 1rem;
        }
      }
    }
  }
`

export const MainImage = styled(BgImage)`
  height: 286px;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
`

export const Summary = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  color: ${({ theme }) => theme.colors.grayscale.white};
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  top: 50%;
  transform: translate(0, -50%);
  @media (min-width: 992px) {
    padding-left: 100px;
    padding-right: 250px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
  }
`

export const HeaderImage = styled(GatsbyImage)`
  object-fit: contain;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.grayscale.gray20};
`

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding: 3rem 2.25rem 3rem 2.25rem;
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  gap: 32px;
  a {
    color: ${({ theme }) => theme.colors.grayscale.white};
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const BlockContent = styled(BlockContentImport)`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  word-wrap: break-word;

  &,
  & p {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 1rem;
    line-height: 1.9;
  }
`

export const Paper = styled.article`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  width: 100%;
  padding: 0 32px;
  // ensure background covers child margins
  overflow: hidden;

  @media (min-width: 576px) {
    padding: 0 48px;
  }
  @media (min-width: 768px) {
    padding: 0 64px;
  }
  @media (min-width: 992px) {
    padding: 0 64px;
  }
`

export const PaperContent = styled.div`
  // use margin for vertical spacing to support collapsing typography margins
  margin: 36px 0;

  @media (min-width: 576px) {
    margin: 72px 0;
  }
  @media (min-width: 768px) {
    margin: 64px 0;
  }
  @media (min-width: 992px) {
    margin: 88px 0;
  }
`
