import styled from 'styled-components'

export const SocialMediaMenu = styled.ul<{ marginLeftAuto: boolean }>`
  display: flex;
  width: 135px;
  margin-bottom: 1rem;
  ${({ marginLeftAuto }) => marginLeftAuto && `margin-left: auto;`}
  ${({ marginLeftAuto }) => !marginLeftAuto && `margin-left: -10px;`}
  li {
    display: block;
    margin: 0 6px;
    position: relative;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid white;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: white;
    }
  }
  .facebook {
    background-color: #3b5998;
  }
  .twitter {
    background-color: #50abf1;
  }
  .linkedin {
    background-color: #0077b5;
  }
`
