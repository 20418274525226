import { graphql } from 'gatsby'
import React from 'react'

import ResearchPage, { ResearchPageProps } from '../components/research/ResearchPage'
import { withIsDispensaryPageContext } from '../hoc/withIsDispensaryPageContext'

type ResearchPageTemplateProps = ResearchPageProps

export const query = graphql`
  query ResearchPageTemplate($id: String!) {
    research: sanityResearch(id: { eq: $id }) {
      id
      mainImage {
        alt
        caption
      }
      title
      keywords
      publishedAt
      roleBasedAccessControl {
        enabled
        roles
      }
      showSocialLinks
      _rawHeaderImage(resolveReferences: { maxDepth: 5 })
      _rawHeader(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawExcerpt(resolveReferences: { maxDepth: 10 })
      _rawMainImage(resolveReferences: { maxDepth: 5 })
      _rawOpenGraphImage(resolveReferences: { maxDepth: 5 })
    }
  }
`

const ResearchPageTemplate: React.FC<ResearchPageTemplateProps> = ({ data, pageContext, path }) => {
  return <ResearchPage pageContext={pageContext} data={data} path={path} />
}

export default withIsDispensaryPageContext(ResearchPageTemplate)
